import logo from '../img/logo.png'

export default function Header() {
    return (
        <header>
            <div className='flex items-center justify-center mb-3 py-5 px-4 sm:px-6 lg:px-8 flex-col'>
            <img src={logo} alt='Cultuurankers aan Zee logo' className="w-64" />
            </div>
        </header>
    )
}