import {ReactComponent as Headphones} from '../img/headphones.svg'

export default function Podcast() {
    return (
      <div className="relative bg-wave-blue py-16 overflow-hidden sm:py-24 lg:py-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <div>
                <Headphones className='w-14 h-14 mx-auto text-white' />
            </div>
            <p className="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
              Podcasts aan de kust
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-white">
                Onderweg of aan een kopje koffie? Luister naar de verhalen uit <a className='plausible-event-name=click-podcast' target='_blank' href="https://castbox.fm/channel/id2760294?country=nl" rel="noreferrer">Scheveningen en Loosduinen</a> van Haags Verhaal.
            </p>
          </div>
        </div>
      </div>
    )
  }