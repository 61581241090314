import { ThumbUpIcon, ThumbDownIcon } from "@heroicons/react/solid"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import classNames from 'classnames'

export default function Votes() {

    const [votes, setVotes] = useState({})
    const [cookies, setCookie, removeCookie] = useCookies(['voted']);

    const getVotes = () => {
        fetch("https://db.cultuurankersaanzee.nl/votes")
        .then(result => result.json())
        .then(result => {
            setVotes(result)
        })
    }

    const vote = async(key, value) => {

        fetch("https://db.cultuurankersaanzee.nl/votes", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...votes,
                [key]: Math.abs(value)
            })
        })
        .then(result => result.json())
        .then(result => {
            setVotes(result)
        })   
    }

    const handleVote = async (key) => {
        if(cookies?.voted) {
            if(cookies?.voted === key) {
                await vote(key, votes[key] - 1)
                removeCookie('voted')
            }
        } else {
            await vote(key, votes[key] + 1)
            setCookie('voted', key)
        }
    }

    useEffect(() => {
        getVotes()
    },[])

    return (
        <div className="bg-wave-blue">
            <div className="max-w-7xl mx-auto py-5 sm:py-16 px-4 sm:px-6 lg:px-8">
                <div className="text-white text-center">
                    <h2 className="text-3xl text-center my-5 font-extrabold tracking-tight text-white">Wandel je mee op 16 september?</h2>
                    <div className="inline-flex justify-center space-x-10 bg-white rounded-full px-8 py-4">
                        <div className={classNames(cookies?.voted === 'down' && "opacity-50","flex items-center space-x-5")}>
                            <div className="font-moderat-black text-gray-800 text-xl">{votes?.up}</div>
                            <button onClick={() => handleVote('up')}><ThumbUpIcon className="w-12 h-12 rounded-full p-2 bg-green-500 shadow"/></button>
                        </div>
                        <div className={classNames(cookies?.voted === 'up' && "opacity-50","flex items-center space-x-5")}>
                            <button><ThumbDownIcon onClick={() => handleVote('down')} className="w-12 h-12 rounded-full p-2 bg-red-500 shadow"/></button>
                            <div className="font-moderat-black text-gray-800 text-xl">{votes?.down}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}