import map from '../img/map.png'
import Button from './Button'

export default function Map() {

return (
    <div className="relative pt-8 pb-16 sm:pt-16 sm:pb-32 overflow-hidden bg-wave-blue">
    <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48" />
    <div className="relative text-center sm:text-left">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-white">
                Wandel mee met Cultuurankers aan Zee
              </h2>
              <div className="mt-4 text-lg text-white">
              <p>Wandel mee van Kijkduin tot Duindorp, van Duindorp tot Kijkduin op zaterdag <strong>16 september 2023</strong> van <strong>12:00-16:00 uur</strong>. Laat je verrassen op het strand en in de duinen, met muziek, theater, beeldende kunst en workshops voor jong en oud.</p>
              <p>Kies zelf je eigen route; activiteiten vinden plaats bij strandslagen 4 t/m 12, in verschillende strandtenten en aan de kustlijn.</p>
              </div>
              <div className="mt-6">
                <Button className="plausible-event-name=click-map-button" href="https://goo.gl/maps/AHmD8gRBJb9s1xd49" >
                  Bekijk alle locaties
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <a target='_blank' className="plausible-event-name=click-map" rel="noreferrer" href="https://goo.gl/maps/AHmD8gRBJb9s1xd49">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={map}
                alt="Inbox user interface"
              />
          </a>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}