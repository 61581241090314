import Header from './components/Header'
import Video from './components/Video'
import Votes from './components/Votes'
import Map from './components/Map'
import Podcast from './components/Podcast'
import Programma from './components/Programma'
import './img/wave.svg'
import logoBDH from './img/logo-BDH.png'
import logoMuzee from './img/logo-muzee.png'
import logoDNR from './img/logo-deregentes.svg'


export default function App() {
  return (
    <div className="bg-white font-moderat">
      <Header/>

      <main>
        
        {/* Hero section */}
        <Video/>

        {/* Votes */}
        <Votes/>

        {/* Map */}
        <Map/>

        {/* Programma */}
        <Programma/>

        {/* Podcast */}
        <Podcast/>

      </main>

      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto pb-8 px-4 sm:px-6 lg:px-8">
          <div className="border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              <a target="_blank" rel="noreferrer" href="https://www.muzeescheveningen.nl/"><img className="h-10 w-auto object-contain" alt="BDH Logo" src={logoMuzee}/></a>
              <a target="_blank" rel="noreferrer" href="https://www.bibliotheekdenhaag.nl/"><img className="h-10 w-auto object-contain" alt="BDH Logo" src={logoBDH}/></a>
              <a target="_blank" rel="noreferrer" href="https://theaterderegentes.nl/"><img className="h-10 w-auto object-contain" alt="BDH Logo" src={logoDNR}/></a>
            </div>
            <p className="mt-8 md:mr-5 text-base text-gray-800 md:mt-0 md:order-1">
              Dit evenement is een samenwerking tussen Cultuurankers Muzee Scheveningen, Bibliotheken Loosduinen | Nieuw Waldeck, en Theater De Regentes. Met dank aan Prioritair gebied Duindorp (Stadsdeel Scheveningen), Gemeente Den Haag, en Fonds1818.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}