import ReactPlayer from 'react-player'
export default function Video() {

return (
    <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-wave" />
            <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
                <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                <div className='relative' style={{paddingTop: '56.25%'}}>
                    <ReactPlayer
                        className='absolute top-0 left-0'
                        width='100%'
                        height='100%'
                        url='https://www.youtube-nocookie.com/watch?v=YWkk79RF-7I'
                        controls={true}
                        config={{
                            youtube: {
                                embedOptions: {
                                    host: 'https://www.youtube-nocookie.com',
                                }
                            }
                        }} 
                    />
                </div>
            </div>
        </div>
    </div>
    )
}