import classNames from 'classnames'

export default function Button({href, className, children, ...props}) {
    return (
        <a
        {...props}
        href={href}
        className={classNames(className, "transition-colors inline-flex bg-orange-custom px-8 py-4 rounded-full border border-transparent text-lg font-medium no-underline shadow-sm text-white hover:bg-orange-600")}
      >
        {children}
      </a>
    )
}