import Button from "./Button";

export default function Programma() {

    const live = true;

    return (
        <div className="bg-white">
          {live ? (
            <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:flex-wrap lg:items-center lg:justify-center">
              <h2 className="w-full tracking-tight text-center text-orange-custom sm:text-2xl">
                <span className="block">Download het programma voor zaterdag 16 september hier:</span>
              </h2>
              <div className="text-center mt-6 space-y-4 sm:space-y-0 sm:space-x-5">
                <Button className='plausible-event-name=click-programme-button' href="https://drive.google.com/file/d/1ROBO_g1dBjDlTNPQg2TkoBWt6pfGUjIf/view?usp=sharing" >
                  Download blokkenschema (PDF)
                </Button>
              </div>
            </div>
          ) : (
            <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 className="tracking-tight text-center text-orange-custom sm:text-2xl mx-auto">
                <span className="block">Het hele programma wordt op 7 augustus bekend gemaakt.</span>
              </h2>
            </div>
          )}
        </div>
    )
}